var mapContainer;
var my_map;
var originalMarkers;
if ( typeof theMapMarkers != 'undefined' ) { 
	console.log(theMapMarkers)
	 originalMarkers = jQuery.extend(true, {}, theMapMarkers);
}

var locationMapStyle = [
   {
       "featureType": "administrative",
       "elementType": "labels.text.fill",
       "stylers": [
           {
               "color": "#444444"
           }
       ]
   },
   {
       "featureType": "landscape",
       "elementType": "all",
       "stylers": [
           {
               "color": "#f2f2f2"
           }
       ]
   },
   {
       "featureType": "poi",
       "elementType": "all",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "geometry",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "geometry.fill",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "geometry.stroke",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "labels",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "labels.text",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "labels.text.fill",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "labels.text.stroke",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.attraction",
       "elementType": "labels.icon",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "poi.park",
       "elementType": "geometry",
       "stylers": [
           {
               "visibility": "on"
           },
           {
               "color": "#c9e2be"
           }
       ]
   },
   {
       "featureType": "poi.park",
       "elementType": "geometry.fill",
       "stylers": [
           {
               "visibility": "on"
           },
           {
               "hue": "#4eff00"
           }
       ]
   },
   {
       "featureType": "poi.park",
       "elementType": "labels.text",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.park",
       "elementType": "labels.text.fill",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi.park",
       "elementType": "labels.text.stroke",
       "stylers": [
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "road",
       "elementType": "all",
       "stylers": [
           {
               "saturation": -100
           },
           {
               "lightness": 45
           }
       ]
   },
   {
       "featureType": "road.highway",
       "elementType": "all",
       "stylers": [
           {
               "visibility": "simplified"
           }
       ]
   },
   {
       "featureType": "road.arterial",
       "elementType": "labels.icon",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "transit",
       "elementType": "all",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "water",
       "elementType": "all",
       "stylers": [
           {
               "color": "#8dc8e8"
           },
           {
               "visibility": "on"
           }
       ]
   }
];


initMaps();

function initMaps() {
	
	if ( typeof theMapMarkers == 'undefined' ) { // Cancel if there are no markers
		return;
	}
		
	mapContainer = $('.google-map').first();
	
	if ( mapContainer.length == 0 ) {
		return; // Cancel if there is no map to put the markers in
	}
	
	var geocoder = new google.maps.Geocoder(),
		my_infobubble = [],
		home_marker = [],
		my_markers = [],
		my_bubbles = [];
		
	map_id = $(mapContainer).attr('id'); // get map name from map div id	
	my_markers = theMapMarkers;
	
	var map_home_lat = $(mapContainer).data('home-lat');
	var map_home_lng = $(mapContainer).data('home-lng');
	var map_zoom = $(mapContainer).data('zoom');
	var map_marker_image_location = '';
	
	// if no lat/lng supplied -then center and zoom right out
	map_home_lat = (map_home_lat !== undefined ? map_home_lat : 51.507010);
	map_home_lng = (map_home_lng !== undefined  ? map_home_lng : -0.136167);
	map_zoom = (map_zoom !== undefined ? map_zoom : 13);
	
	//console.log(map_home_lat, map_home_lng);
	
	home_location=new google.maps.LatLng(map_home_lat, map_home_lng);
	
	
	
	var styledMap = new google.maps.StyledMapType(locationMapStyle,{name: "Styled Map"});
	
	var mapOptions = {
		zoom: map_zoom,
		center: home_location,
		scrollwheel: true,
		draggable: true,
		zoomLevel: 12,
		streetViewControl: false,
		panControl: false,
		zoomControl: true,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.SMALL,
			position: google.maps.ControlPosition.RIGHT_BOTTOM
		},
		mapTypeControl: false,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
		}
	};
	
	my_map = new google.maps.Map(document.getElementById(map_id), mapOptions);
	
	//Associate the styled map with the MapTypeId and set it to display.
	my_map.mapTypes.set('map_style', styledMap);
	my_map.setMapTypeId('map_style');
	
	
	// Override the built in google maps POI infowindow setup
	// method to control the inner html ourselves
	var set = google.maps.InfoWindow.prototype.set;
	google.maps.InfoWindow.prototype.set = function (key, val) {
		var self = this;
		if (key === "map") {
			if ($(this.content).find('.custom-close-button').length==0)
				{
					var close_button = $("<a class='custom-close-button' href='#'></a>");
					$(this.content).find(".transit-container").append(close_button);
					close_button.click(function(e) { e.preventDefault();self.close(); });
				}
		}
		set.apply(this, arguments);
	}

	
	// plot any markers if json defined in map tag
	var i=0;
	$.each(my_markers, function(key, value) {
		var icon_URL = map_marker_image_location + (value.marker_icon!==undefined ? value.marker_icon : '/assets/img/orange-pin.png');
		//console.log(value.marker_icon);
		//var icon_URL = 'images/map-marker.png';
		plot_marker(map_id, i, value.html, icon_URL, value.address, value.lat, value.lng);
		
		// Also create the info bubble
		my_bubbles[i] = new InfoBubble({
			map: my_map,
			position: new google.maps.LatLng(value.lat, value.lng),
			shadowStyle: 1,
			padding: 10,
			backgroundColor: 'rgb(255, 255, 255)',
			borderRadius: 0,
			borderWidth: 1,
			borderColor: '#4f4f4f',
			arrowSize: 10,
			disableAutoPan: false,
			hideCloseButton: 0,
			arrowStyle: 0,
			arrowSize: 0,
			arrowPosition: 50,
			backgroundClassName: 'infobubble',
			minWidth: 160,
			minHeight: 70,
			maxWidth: 365,
			maxHeight: 150,
			disableAnimation: true,
			disableAutoPan: true,
			closeSrc: '/assets/img/map-close.png'
		});
		var html = value.html;
		html = $('<div/>').html(html).text();
		html = deentitize(html);
		my_bubbles[i].setContent(html);
		i++;
	});
	

	

	
	function plot_marker(map_id, marker_index, html, icon_URL, address, lat, lng) {
				
		var marker_location;

		// build marker latlng or address
		if (lat!==undefined && lng!==undefined) {
			marker_location=new google.maps.LatLng(lat,lng);
			initialise_marker();
		} else {
			return false;
		}

		function initialise_marker() {
			my_markers[marker_index] = new google.maps.Marker({
				position: marker_location,
				map: my_map,
				icon: icon_URL,
				zIndex: (marker_index == 0 ? 1000 : 0 )	// first marker at top - others let google decide
			});

			google.maps.event.addListener(my_markers[marker_index], 'mouseover', function() {
				for (bubble in my_bubbles) {
					my_bubbles[bubble].close();
				}
				my_bubbles[marker_index].open(my_map, my_markers[marker_index]);
			});

			// google.maps.event.addListener(my_map, 'zoom_changed', function() {
			// 	var icon = {
			// 		url: icon_URL,
			// 		anchor: new google.maps.Point(15, 30),
			// 		size: new google.maps.Size(30, 30)
			// 	}
			// 	for (marker in my_markers) {
			// 		my_markers[marker].setIcon(icon)
			// 	}
			// })

			// google.maps.event.addListener(my_map, 'drag', function() {
			// 	var icon = {
			// 		url: icon_URL,
			// 		anchor: new google.maps.Point(15, 30),
			// 		size: new google.maps.Size(30, 30)
			// 	}
			// 	for (marker in my_markers) {
			// 		my_markers[marker].setIcon(icon)
			// 	}
			// })

		}

	}
	
	function deentitize(str) {
		str = str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
		str = str.replace(/&#39;/g, "'").replace(/&quot;/g, '"');
		return str;
	}
	



} 



