$(document).foundation();
$(document).ready( function () {

	patternlib();

	

	Dropdown.initialise;
	$('.dropdown li ul').each( function () {
		$(this).css('left', ($(this).parent().outerWidth() - $(this).outerWidth()) / 2 + 'px' );
	});
	
	// Mobile burger menu toggles
	$('.sub-menu-toggle').on('click', function () {
		if ( $('.burger-nav-primary.open').length > 0 ) { // other items are open, so close them
			$('.burger-nav-primary.open').removeClass('open');
		}
		
		if ( $(this).next().length > 0 ) { // Only toggle the sub items if there are any
			$(this).parent().toggleClass('open');
			return false;
		}
	});
	
	
	
	
	
	fixHeader();
	$(document).on('scroll', _.throttle(fixHeader, 100));
	
	function fixHeader() {
		if ($(window).scrollTop() >= 50) { // was 72
			$('body').addClass('fix-header');
		} else {
			$('body').removeClass('fix-header');
		}
	}
	
	
	
	// Apply half height to horizontal landing grid items
	$('.landing-content.row[data-equalizer]').on('preequalized.zf.equalizer', function () {
		// Clear the set heights
		$('.landing-item-horizontal').css('height', 'auto');
	});
	
	$('.landing-content.row[data-equalizer]').on('postequalized.zf.equalizer', equalizeLanding);
	
	setTimeout(equalizeLanding, 750);
	
	function equalizeLanding() {
		$('.grid-2-columns').each( function () {
			var rowHeight = $(this).parent().outerHeight();
			var halfHeight = (rowHeight / 2 - 33); // 33 is the magic number to allow for the bottom margin on the rows
			if (halfHeight < 200) {
				halfHeight = 200;
			}
			
			$(this).find('.landing-item-horizontal').css('height', halfHeight + 'px'); 
			
			$(this).parent().find('.landing-item-no-image').css('height', (halfHeight * 2 + 33) + 'px'); 
		});
	}
	
	// Reset the auto-sizing if on mobile
	if ($('.landing-content').length > 0) {
		$(window).on('resize', function () {
			if ( $(window).innerWidth() <= 640 ) {
				$('.landing-item-horizontal').css('height', 'inherit');
			} 
		});
	}
	

	
	
	
	
	$('.slider').reformSlider({
		arrows: true,
		speed: 3500,
		animationSpeed: 500
	});
	
	$('.home-slider').slick({
		arrows: true,
		autoplaySpeed: 3500,
		speed: 1250,
		fade: true,
		autoplay: true
	});
	
	
	
	$('.accordion').accordiom();
	
	
	// Gallery popup template
	$('.gallery-grid-item a').on('click', function () {
		
		// Do nothing if on a mobile
		if ( $(window).innerWidth() <= 640 ) return false;
		
		$('.gallery-grid').slideUp();
		$('.gallery-popup').slideDown();
		
		if ( !$('body').hasClass('gallery-slider-initiated') ) {
			// Init the slider
			$('.gallery-slider').slick({
				arrows: true,
				fade: true
			});
			
			// Add each gallery image to the slider
			$('.gallery-grid-item').each( function () {
				var slideMarkup = '<div class="gallery-slide">';				
				slideMarkup += '<img src="' + $(this).find('a').first().attr('href') + '">';
				
				if ( typeof($(this).data('image-title')) != undefined ) {
					slideMarkup += '<div class="gallery-slide-caption">';
					slideMarkup += '<h2>' + ($(this).data('image-title') || '') + '</h2>';
					slideMarkup += '<p>' + ($(this).data('image-caption') || '') + '</p>';
					slideMarkup += '<div class="body-cta">';
					slideMarkup += '<p>' + ($(this).data('image-price') || '') + '</p>';
					
					if ( typeof($(this).data('image-cta-link')) != 'undefined' ) {
						slideMarkup += '<div class="double-cta"><a class="cta-button" href="' + $(this).data('image-cta-link') + '">' + ($(this).data('image-cta-text') || '') + '</a></div>';
					}
					
					slideMarkup += '</div></div>';
				}
				slideMarkup += '</div>';
				
				$('.gallery-slider').slick('slickAdd', slideMarkup);
			});
			
			$('body').addClass('gallery-slider-initiated');
		}		
		
		// Jump to the correct starting slide
		$('.gallery-slider').slick('slickGoTo', $('.gallery-grid-item').index( $(this).parent() ) );
		
		
		
		
		return false;
	});
	
	
	$('.gallery-close').on('click', function () {
		$('.gallery-grid').slideDown();
		$('.gallery-popup').slideUp();
	});



	if ( $('body.no-popup').length < 1 ) {
		setTimeout(function() {
			$('.header-nav-primary-book-direct > button').trigger("click");
		}, 1000);
	} 
	
	
	
	
	inlineSVGs('.footer-social img');
	
	animateBorders();
	
	
});


// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(selectorString) {
	
	$(selectorString).each(function(){
	    var $img = $(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');
	
	    $.get(imgURL, function(data) {
	        // Get the SVG tag, ignore the rest
	        var $svg = $(data).find('svg');
	
	        // Add replaced image's ID to the new SVG
	        if(typeof imgID !== 'undefined') {
	            $svg = $svg.attr('id', imgID);
	        }
	        // Add replaced image's classes to the new SVG
	        if(typeof imgClass !== 'undefined') {
	            $svg = $svg.attr('class', imgClass+' replaced-svg');
	        }
	
	        // Remove any invalid XML tags as per http://validator.w3.org
	        $svg = $svg.removeAttr('xmlns:a');
	
	        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	        }
	
	        // Replace image with new SVG
	        $img.replaceWith($svg);
	
	    }, 'xml');
	
	});	
	
}




function animateBorders() {
	
	$('.home-rollover, .home-large-image, .home-square-rollover, .home-text-panel, .landing-item').append('<span class="rollover-border-top"></span> <span class="rollover-border-right"></span> <span class="rollover-border-bottom"></span> <span class="rollover-border-left"></span>');
	
}

